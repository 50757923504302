<template>
  <div class="about">

    <aboutpage/>

  </div>
</template>

<script>
import aboutpage from "@/components/aboutpage";


export default {
  name: 'Home',
  components: {

    aboutpage
  }
}
</script>

