<template>
  <div id="tech">
    <div class="container">
      <div class="cardtech" v-for="n in datasettech" :key="n.id">
        <p class="cardtitle">{{n[0]}}</p>
        <p class="descriptiontech">
          {{n[2]}}
        </p>
        <div class="buttongototech" @click="gotooffcialsite(n[1])">
          {{n[0]}} official site
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tech",
  data(){
    return{
      datasettech: [
          ['Django', 'https://www.djangoproject.com/', 'Django very fast back-end framework. Backend logic is built on it very quickly and safely'],
          ['Vue', 'https://ru.vuejs.org/', 'Progressive JavaScipt framework for fast and beaty interfaces'],
          ['PostgreSQL', 'https://www.postgresql.org/', 'The best and free database in the world'],
      ]
    }
  },
  methods:{
    gotooffcialsite(url){
      window.open(url)
    }
  }
}
</script>

<style scoped>
#tech{
  height: 80vh;
  width: 100%;
  background-color: #131313;
  overflow-y: scroll;
  -ms-overflow-y: scroll;
}

::-webkit-scrollbar{
  background: transparent;
}

.container{
  width: 90%;
  margin-left: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}
.cardtech{
  height: 50vh;
  min-height: 100px;
  min-width: 270px;
  margin-top: 30px;
  margin-bottom: 60px;
  width: 300px;
  box-shadow: 0px 0px 10px 1px #24bb24;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .cardtech{
    height: 70vh;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cardtech{
    height: 70vh;
  }
}

@media (max-width: 480px) {
  .cardtech{
    height: 60vh;
  }
}

@media (min-width: 480px) and(max-width: 767px) {
  .cardtech{
    height: 100vh;
  }
}

.cardtitle{
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  padding-top: 10%;
  padding-bottom: 10%;
  height: 10%;
}
.descriptiontech{
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  font-size: 1.5rem;
  text-align: justify;
}
.buttongototech{
  width: 80%;
  margin-left: 10%;
  height: 10%;
  min-height: 49px;
  margin-top: 10%;
  display: flex;
  border: 2px solid #24bb24;
  border-radius: 20px;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  transition: .2s;
}
.buttongototech:hover{
  background-color: #24bb24;
  transition: .2s;
  cursor: pointer;
}
</style>