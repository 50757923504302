<template>
  <div class="aboutpage">
    <div class="abouttext">
      <p class="textabout">
        Hello. My name Elisey Kravchuk.
      I am {{years}} years old. I am full-stack developer.
      I am programming since 12 years old. You can see my work on the <span class="gotoport" style="transition: .4s;text-decoration: underline" @click="$router.push('/portfolio')">portfolio</span> page
      </p>
    </div>
    <div class="avatarwrapper">
      <div class="avatar">
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "aboutpage",
    mounted() {
      let milliseconds_in_year = 31536000000
      this.years = Math.floor(((new Date() - new Date("April 18, 2004")) / milliseconds_in_year));
    },
    data() {
        return {
            years: null,
        }
    },
}
</script>

<style scoped>
.aboutpage{
  height: 80vh;
  width: 100%;
  background-color: #131313;
  color: white;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
.abouttext{
  display: flex;
  justify-content: center;
  width: 60%;
}
.textabout{
  width: 70%;
  font-size: 2vw;

}
.avatarwrapper {
  min-width: 200px;
  min-height: 200px;
  display: flex;
  justify-content: center;
}
.avatar{
  width: 300px;
  height: 300px;
  background-image: url("../assets/img/index.jpeg");
  background-size: cover;
  border-radius: 50%;
  margin-right: 30px;
}
@media screen and (max-width: 600px) {
  div.avatar {
    margin-right: 0;
    width: 200px;
    height: 200px;
  }
  .textabout{
    width: 80%;
    text-align: justify;
    font-size: 5vw;
  }
  .abouttext{
    width: 100%;
    display: flex;
    justify-content: center;
  }

}
@media screen and (max-width: 900px) {
  div.avatarwrapper {
      width: 100%;
    justify-content: center;
  }
}
.gotoport:hover{
  cursor: pointer;
  color: #24bb24;
  transition: .4s;
}
</style>
