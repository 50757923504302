<template>
  <div id="portfolio">
    <div class="container">
      <p class="portext">Portfolio</p>

<!--      Card works-->
      <div class="cardwrapper">
        <div class="card" v-for="n in worksdata" :key="n.id" @click="gotosite(n[1])">
          <p class="projectname">{{n[0]}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Portfolio",
   data(){
    return{
      worksdata: [
        // ['Fexer', 'https://fexer.herokuapp.com/', 'On delopment'],
        ['Space', 'https://space-sigma.vercel.app/', 'On delopment'],
        // ['MayaKraina', 'https://mayakraina.kangreon.ru/', 'Site with test for school championship'],
      ],
      widthscreen: this.$store.state.screentype
    }
   },
  methods:{
    gotosite(url){
      window.open(url)
    }
  },
  created() {
    console.log(this.widthscreen)
  }
}
</script>

<style scoped>
#portfolio{
  width: 100%;
  height: 80vh;
  background-color: #131313;
  color: white;
  overflow-y: scroll;
}
.container{
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.portext{
  width: 100%;
  font-size: 1.6rem;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.cardwrapper{
  display: flex;
  align-items: center;
  width: 100%;
  height: 70vh;
  flex-wrap: wrap;
  justify-content: space-around;

}
.card{
  height: 50vh;
  width: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: sans-serif;
  font-size: 2em;
  letter-spacing: 4px;
  background: rgba(36, 187, 36, 0.7);
  margin-bottom: 20px;
  border-radius: 10px;
  color: white;
  /*-webkit-background-clip: text;*/
  /*-webkit-text-fill-color: rgba(255, 255, 255, 0);*/
}

@media (min-width: 1200px) {
  .card{
      color: #171717;
      background: linear-gradient(0deg, #24bb24, #131313);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: 40vh;
      transition: 1s;
  }
  .card:hover{
    background-position: 0;
    transition: .4s;
    color: white;
    cursor: pointer;
}
}


.projectname{
  margin-top: 10%;
  display: flex;
  justify-content: center;
  z-index: 999999;
}
</style>